import { GROUP_UPDATE, GROUP_REMOVE } from './group.actions';
import { CONTACT_LOGGED_OUT } from '../contact/contact.actions';

const groupReducer = (state = null, action) => {
    switch (action.type) {
        case GROUP_UPDATE:
            return action.value;
        case GROUP_REMOVE:
        case CONTACT_LOGGED_OUT:
            return null;
        default:
            return state;
    }
}

export default groupReducer;