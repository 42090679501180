import React from 'react';

// CSS Loaders
import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';

const Spinner = () => (
    <div className="spinner-wrapper">
        <div className="ball-clip-rotate-multiple">
            <div></div>
            <div></div>
        </div>
    </div>
)

export default Spinner;
