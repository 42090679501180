/**
 * Shop actions
 */
export const SHOP_INIT = 'SHOP_INIT';
export function shopInit() {
    return { type: SHOP_INIT };
}

export const SHOP_UPDATE = 'SHOP_UPDATE';
export function shopUpdate(name, value) {
    return { type: SHOP_UPDATE, name, value };
}

export const SHOP_ORDER_COMPLETE = 'SHOP_ORDER_COMPLETE';
export function shopOrderComplete() {
    return { type: SHOP_ORDER_COMPLETE, name: 'cart' };
}

/**
 * Cart actions
 */
export const SHOP_CART_UPDATE = 'SHOP_CART_UPDATE';
export function shopCartUpdate(value) {
    return { type: SHOP_CART_UPDATE, name: 'cart', value };
};


export const SHOP_CART_REMOVE = 'SHOP_CART_REMOVE';
export function shopCartRemove() {
    return { type: SHOP_CART_REMOVE };
};