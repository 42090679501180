import React, { Component } from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import routes from '../../routing/routes';
import { UtilUrl } from '../../utils/url.util'

class Footer extends Component {

    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                <span className="footer-parts">
                    <span className="footer-part">
                        &copy; {year} Reservi
                    </span>
                    <span className="footer-part">
                        Een product van <a href="https://www.sanmax.be" target="_blank" rel="noopener noreferrer">Sanmax Projects</a>
                    </span>
                    <span className="footer-part">
                        <Link to={{ pathname: UtilUrl.generate(routes.privacypolicy, {}) }}>
                            {t('footer:privacy-policy.text')}
                        </Link>
                    </span>
                    <span className="footer-part">
                        <a href={t('footer:general-conditions.link')} target="_blank" rel="noopener noreferrer">{t('footer:general-conditions.text')}</a>
                    </span>
                    {/*
                    <span className="footer-part">
                    <Link to={{ pathname: UtilUrl.generate(routes.terms, {}) }}>
                        {t('footer:general-conditions.text')}
                    </Link>
                    </span>
                    */}

                </span>
            </footer>
        );
    }

}

export default Footer;
