import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";

// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import { withNamespaces } from 'react-i18next';

import configureStore from './store/store';
const store = configureStore();


class App extends Component {
  componentDidMount() {
    const { settings } = store.getState();

    if (!settings) {
      return;
    }

    const { language } = settings

    if (this.props.i18n.language !== language) {
      this.props.i18n.changeLanguage(language);
    }
  }

  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
      <BrowserRouter basename={basename}>
        <Routes />
      </BrowserRouter>
    );

  }
}

export default withNamespaces()(App);