import { TOGGLE_SETTING, CHANGE_SETTING } from './settings.actions';
import { CONTACT_LOGGED_OUT } from '../contact/contact.actions';

const initialSettings = {
    language: 'nl'
};

const settingsReducer = (state = initialSettings, action) => {
    switch (action.type) {
        case TOGGLE_SETTING:
            return {
                ...state,
                [action.name]: !state[action.name]
            }
        case CHANGE_SETTING:
            return {
                ...state,
                [action.name]: action.value
            };
        case CONTACT_LOGGED_OUT:
            return initialSettings;
        default:
            return state;
    }
}

export default settingsReducer;