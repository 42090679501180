/**
 * Contact actions
 */
export const CONTACT_LOGGED_IN = 'CONTACT_LOGGED_IN';
export const CONTACT_LOGGED_OUT = 'CONTACT_LOGGED_OUT';

export function contactLoggedIn(value) {
    return { type: CONTACT_LOGGED_IN, value };
};

export function contactLoggedOut() {
    return { type: CONTACT_LOGGED_OUT };
};

export const CONTACT_SET = 'CONTACT_SET';
export const CONTACT_UPDATE = 'CONTACT_UPDATE';
export const CONTACT_UPDATE_SETTINGS = 'CONTACT_UPDATE_SETTINGS';
export const CONTACT_REMOVE = 'CONTACT_REMOVE';

export function contactSet(name, value) {
    return { type: CONTACT_SET, name, value };
};

export function contactUpdate(name, value) {
    return { type: CONTACT_UPDATE, name, value };
};

export function contactUpdateSettings(name, value) {
    return { type: CONTACT_UPDATE_SETTINGS, name, value };
};

export function contactRemove(name) {
    return { type: CONTACT_REMOVE, name };
};

/**
 * Contact Access key actions
 */
export const CONTACT_ACCESS_KEY_SET = 'CONTACT_CONTACT_ACCESS_KEY_SET';
export const CONTACT_ACCESS_KEY_REMOVE = 'CONTACT_CONTACT_ACCESS_KEY_REMOVE';

export function contactAccessKeySet(value) {
    return { type: CONTACT_ACCESS_KEY_SET, value };
};

export function contactAccessKeyRemove() {
    return { type: CONTACT_ACCESS_KEY_REMOVE };
};