import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/PageLoader';

import Base from './components/layout/Base';
import BasePage from './components/layout/BasePage';

import routes from './routing/routes';
import ErrorBoundary from './containers/Error/ErrorBoundary';
import PrivateRoute from './routing/PrivateRoute';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

// Authentication
const Login = lazy(() => import('./containers/Pages/Login'));
const Register = lazy(() => import('./containers/Pages/Register'));
const ForgotPassword = lazy(() => import('./containers/Pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./containers/Pages/ResetPassword'));

const Terms = lazy(() => import('./containers/Pages/Terms'));
const PrivacyPolicy = lazy(() => import('./containers/Pages/PrivacyPolicy'));

const NotFound = lazy(() => import('./containers/Pages/NotFound'));
const ErrorPage = lazy(() => import('./containers/Pages/Error500'));

const AutoRequest = lazy(() => import('./containers/Pages/AutoRequest'));

const Shop = lazy(() => import('./containers/Shop/Shop'));

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    const animationName = ''; // 'rag-fadeIn'

    if (
        location.pathname.includes('/auth/') === true ||
        location.pathname.includes('/e/') === true
    ) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path={routes.e} component={waitFor(AutoRequest)} />

                        {/* See full project for reference */}
                        <Route exact path={routes.auth.forgotpassword} component={waitFor(ForgotPassword)} />
                        <Route exact path={routes.auth.resetpassword} component={waitFor(ResetPassword)} />
                        <Route exact path={routes.auth.login} component={waitFor(Login)} />
                        <Route exact path={routes.auth.register} component={waitFor(Register)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else {
        return (
            // Layout component wrapper
            <Base>
                <ErrorBoundary>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <PrivateRoute path={routes.shop.overview} component={waitFor(Shop)} />

                                    <Route path={routes.terms} component={waitFor(Terms)} />
                                    <Route path={routes.privacypolicy} component={waitFor(PrivacyPolicy)} />

                                    <Route path={routes.error} component={waitFor(ErrorPage)} />
                                    <Route path={routes.notfound} component={waitFor(NotFound)} />

                                    <Redirect to={routes.notfound} />
                                </Switch>
                            </Suspense>
                        </CSSTransition>
                    </TransitionGroup>
                </ErrorBoundary>
            </Base>
        )
    }
};

export default withRouter(Routes);