import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import HeaderRun from './Header.run'
import { UtilUrl } from '../../utils/url.util';
import routes from '../../routing/routes';
import { API } from '../../services/api';
import DropdownLanguageSelect from '../dropdown/DropdownLanguageSelect';
import { t } from 'i18next';

class Header extends Component {

    componentDidMount() {
        HeaderRun();
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    logout = e => {
        e.preventDefault();

        if (this.props.contact.urls) {
            API.get(this.props.contact.urls.logout, {})
                .then(response => {
                    this.redirect();
                });
        } else {
            this.redirect();
        }
    }

    redirect = () => {
        let redirect = this.props.settings.afterLoginUrl;
        this.props.actions.contactLoggedOut();
        this.props.history.push(redirect);
    }

    render() {
        const { contact, group } = this.props;

        let logo = 'images/reservi-logo.svg';
        let alt = 'Reservi logo';
        if (group && group.logo) {
            logo = group.logo;
            alt = group.name + ' logo';
        }

        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <div className="navbar-brand">
                            <div className="brand-logo">
                                <img className="img-fluid" src={logo} alt={alt} />

                                <div className="brand-tagline">
                                    by <img className="img-fluid" src="images/reservi-logo.svg" alt="Reservi logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">

                    </ul>
                    { /* END Left navbar */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        {this.props.contact && (
                            <li className="nav-item d-none d-md-block">
                                <span className="navbar-text nav-text">
                                    {t('header.welcome', { name: contact.contact.firstName + ' ' + contact.contact.lastName, nationalInsuranceNumber: contact.contact.nationalInsuranceNumber })}
                                </span>
                            </li>
                        )}

                        {this.props.contact && (group && group.settings.manage) && (
                            <li className="nav-item d-none d-md-block">
                                <a href={UtilUrl.generate(routes.contact.overview)} className="nav-link">
                                    <em className="icon-calendar mr-1"></em>
                                    {t('contact.appointment:title')}
                                </a>
                            </li>
                        )}

                        <DropdownLanguageSelect />
                        { /* START logout */}

                        {this.props.contact && (
                            <React.Fragment>
                                <li className="nav-item d-none d-md-block">
                                    <a className="nav-link" href="#" onClick={this.logout}>
                                        <em className="icon-logout"></em>
                                    </a>
                                </li>
                            </React.Fragment>

                        )}
                        { /* END logout */}
                    </ul>
                    { /* END Right Navbar */}
                </nav>
                { /* END Top Navbar */}
            </header>
        );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings ? state.settings : {}, contact: state.contact ? state.contact : null, group: state.group ? state.group : null });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);