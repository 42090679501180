import { include } from 'named-urls';

export default {
    notfound: '/404-not-found',
    error: '/500-error',
    terms: '/terms',
    privacypolicy: '/privacy-policy',

    auth: include('/auth', {
        login: 'l/:path',
        forgotpassword: 'fp/:path',
        resetpassword: 'rp/:path',
        register: 'r/:path',
        confirm: 'c/:path'
    }),

    e: '/e/:type/:path',

    shop: include('/shop', {
        overview: '',
        category: 'category/:path',
        search: 'search/:search',
        cart: 'cart',
        order: include('order', {
            confirmation: 'confirmation/:path?'
        })
    })

    /*group: include('/g', {
        overview: ':path/:date?',
        optimal: 'o/:path/:date?'
    }),

    agenda: include('/c', {
        overview: ':path/:date?',
        move: 'm/:path/:date?'
    }),

    type: include('/t', {
        overview: ':path',
        agenda: 'c/:path/:date?',
        group: 'g/:path/:date?',
        group_merged: 'gm/:path/:date?',
        move: 'm/:path/:date?',
        list: 'l/:path'
    }),

    appointment: include('/a', {
        overview: '',
        book: 'b/:path',
        agenda: 'cl/:path',
        confirmation: 'c/:path',
        edit: 'e/:path',
        manage: 'm/:path/:entrance?',
        print: 'p/:path'
    }),

    contact: include('/p', {
        overview: '',
        edit: 'e/:path',
        appointments: 'a'
    })*/
}