import { GROUP_UPDATE } from "../group/group.actions";
import { API } from "../../services/api";

/*
   Hook into setting changes in order to change layout.
*/
const group = store => next => action => {
    let result = next(action);

    if (action.type === GROUP_UPDATE && action.value) {
        API.setGroup(action.value.id);
    }

    return result
}

export default group;