import React from 'react';
import { t } from 'i18next';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';

class DropdownLanguageSelect extends React.Component {
    constructor(props) {
        super(props);
        
        const { settings } = props;

        this.state = {
            dropdownOpen: false,
            language: settings.language,
            languages: ['nl', 'fr']
        }
    }

    render() {
        const { language } = this.state;

        return (
            <UncontrolledDropdown nav inNavbar className="dropdown-list">
                <DropdownToggle nav className="dropdown-toggle-nocaret">
                    <img src={'/images/languages/' + (language === 'nl' ? 'be' : language) + '.png'} alt={t('language:' + language)} width="20px" className="mr-1" />
                    <span>{t('language:' + language)}</span>
                </DropdownToggle>
                { /* START Dropdown menu */}
                <DropdownMenu right className="dropdown-menu-right animated fadeIn">
                    <DropdownItem>
                        { /* START list group */}
                        <ListGroup>
                            {this.renderListItems()}
                        </ListGroup>
                        { /* END list group */}
                    </DropdownItem>
                </DropdownMenu>
                { /* END Dropdown menu */}
            </UncontrolledDropdown>
        );
    }

    renderListItems = () => {
        const { languages } = this.state;

        let renderedItems = [];

        languages.forEach((language, i) => {
            renderedItems.push(
                <ListGroupItem action key={i} onClick={() => this.changeLanguage(language)}>
                    <div className="media">
                        <div className="align-self-start mr-2">
                            <img src={'/images/languages/' + (language === 'nl' ? 'be' : language) + '.png'} alt={t('language:' + language)}  width="20px" className="mr-1" />
                        </div>
                        <div className="media-body">
                            <p className="m-0">
                                {t('language:' + language)}
                            </p>
                            
                        </div>
                    </div>
                </ListGroupItem>
            );
        });

        return renderedItems;
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    changeLanguage = lng => {
        this.props.i18n.changeLanguage(lng);
        this.props.actions.changeSetting('language', lng);

        this.setState({
            language: lng
        });
    }
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withNamespaces()
)(DropdownLanguageSelect);